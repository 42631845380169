.mat-mdc-menu-panel {
    max-width: 22rem !important;
    max-height: 27rem !important;
    min-width: 15.2rem !important;
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-size: 1.4rem !important;
    line-height: 1.5rem !important;
}

.mat-mdc-menu-item {
    min-height: 3.5rem !important;
    color: $color-font !important;
    --mat-menu-item-hover-state-layer-color: #{$color-primary};
    --mat-menu-item-focus-state-layer-color: transparent;

    &:hover {
        color: $color-white !important;
        --mat-menu-item-focus-state-layer-color: #{$color-primary};

        & .mat-mdc-menu-submenu-icon {
            color: $color-white !important;
        }
    }

    & .mat-mdc-menu-submenu-icon {
        color: $color-font !important;
    }
}