.toggle-group-design {

    .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
    .mat-button-toggle-group-appearance-standard {
        border: none;
    }

    .mat-button-toggle button {
        border-radius: 0.4rem;
        border: none;
        font-weight: 500;
        cursor: pointer;
        background-color: $color-grey-light-1;
    }

    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        line-height: 3rem;
        color: $color-font;
    }

    .mat-button-toggle.active {
        .mat-button-toggle-label-content {
            color: $color-white;
            background-color: $color-primary;
        }
    }


    .mat-button-toggle-appearance-standard {
        background-color: $color-grey-light-1;
    }

    &-border {
        .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
            line-height: 2.5rem;
        }

        .mat-button-toggle button {
            margin: .35rem;
            width: auto;
        }

        .mat-button-toggle-checked button {
            margin: .25rem;
        }
    }
}

.mat-button-toggle .mat-pseudo-checkbox {
    display: none;
}

.toggle-group-design .mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard .mat-button-toggle-appearance-standard+.mat-button-toggle-appearance-standard {
    border-left: .1rem solid #D9D9D9 !important;
    border: .1rem solid #D9D9D9 !important;
    border-radius: .5rem;
}