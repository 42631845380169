/*
0 - 600px:  Phone
600 - 900px:    Tablet Portrait
900 - 1200px:   Tablet landscape
1200 - 1800px: Is where our normal styles apply

1800px + : Big Desktops

$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components
*/
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}
@media only screen and (max-width: 85em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/Inter-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/Inter-Italic-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 100 900;
  font-style: italic;
  font-display: swap;
}
* {
  font-family: "Inter", sans-serif;
}

body {
  box-sizing: border-box;
  font-size: 1.4rem;
  color: #606569;
  background-color: #f7f7f7;
  font-weight: 400;
  line-height: 1.5;
}

p {
  font-weight: 400;
}

em,
i {
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-size: 1.2rem;
  color: #606569;
}

h2 {
  color: #1da3d4;
  font-size: 1.8rem;
  font-weight: 500;
}

a {
  color: #1da3d4;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

/* For Chrome and Edge */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3rem #f5f7f8 inset !important;
  box-shadow: 0 0 0 3rem #f5f7f8 inset !important;
}

/* For Firefox */
input:-moz-autofill {
  background-color: #f5f7f8 !important;
}

/* For Safari */
input:-webkit-autofill {
  background-color: #f5f7f8 !important;
}

button,
table {
  box-shadow: none !important;
}

.advocate-form {
  margin-bottom: 3.2rem;
  text-align: left;
}
.advocate-form:last-child {
  margin-bottom: 2rem;
}
.advocate-form-row {
  display: block;
  margin-bottom: 2rem;
}
.advocate-form-row:last-child {
  margin-bottom: 0 !important;
}
.advocate-form-row__label {
  text-align: left;
  letter-spacing: normal;
  margin-bottom: 0.8rem;
}
.advocate-form-row__content {
  position: relative;
  cursor: pointer;
}
.advocate-form-row-group {
  display: block;
  position: relative;
}
.advocate-form-row-group--separated {
  border-bottom: 10px #777 solid;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}
.advocate-form-row-group:last-child {
  margin-bottom: 0;
}
.advocate-form-subrow {
  display: flex;
  flex-direction: row;
}
.advocate-form-subrow--heading {
  align-items: center;
  margin-bottom: 0.625rem;
}
.advocate-form-subrow--separated {
  border-bottom: 1px #777;
  padding-bottom: 0.5rem;
}
.advocate-form-subrow--separated:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.advocate-form-subrow--spaced {
  justify-content: space-between;
}
.advocate-form-subrow__col {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 0.25rem;
}
.advocate-form-subrow__col:not(:last-child) {
  margin-right: 3.2rem;
}
.advocate-form-subrow__col--stretched {
  flex: 1;
}
.advocate-form-subrow__label {
  display: block;
  margin-bottom: 0.8rem;
}
.advocate-form .cux-textbox {
  display: block;
  height: 5.5rem;
  width: 100%;
  padding-left: 2rem;
}
.advocate-form .cux-textbox--inline {
  display: inline;
  width: auto;
}

.advocate-form-label {
  display: inline-flex;
  flex-direction: row;
}
.advocate-form-label:last-child {
  margin-right: 0;
}

.advocate-help-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.6rem;
  margin-bottom: -1rem;
}
.advocate-help-text--icon {
  display: flex;
  align-items: center;
  margin-top: -0.8rem;
}
.advocate-help-text--error {
  color: #d23927;
  font-size: 1.2rem;
}

.advocate-row-chips {
  margin-top: 1rem;
}

.advocate-required-field::after {
  content: "*";
}

.cux-icon {
  height: 2.4rem;
  width: 2.4rem;
  font-size: 2.4rem;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
}
.cux-icon--extra-small {
  height: 1.8rem;
  width: 1.8rem;
  font-size: 1.8rem;
}
.cux-icon--small {
  height: 2rem;
  width: 2rem;
  font-size: 2rem;
}
.cux-icon--medium {
  height: 3.2rem;
  width: 3.2rem;
  font-size: 3.2rem;
}
.cux-icon--large {
  height: 4rem;
  width: 4rem;
  font-size: 4rem;
}
.cux-icon--primary {
  color: #1da3d4;
}
.cux-icon--success {
  color: #1da3d4;
}
.cux-icon--danger {
  color: #d23927;
}
.cux-icon--muted {
  color: #bbc3c9;
}
.cux-icon--white {
  color: #fff;
}

.cux-textbox {
  appearance: none;
  background: #f5f7f8;
  border: none;
  border-radius: 1rem;
  box-sizing: border-box;
  color: #606569;
  display: inline-block;
  font-size: 1.4rem;
  line-height: 2.8rem;
  margin: 0;
  padding-left: 1.6rem;
  height: 4.8rem;
}
.cux-textbox::placeholder {
  color: #bbc3c9;
  font-size: 1.4rem;
  line-height: 2.8rem;
  overflow: visible;
}
.cux-textbox:focus {
  border: 2px solid #1da3d4;
  outline: none;
}
.cux-textbox--stretched {
  width: 100%;
}
.cux-textbox__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 4rem;
  width: 4rem;
  cursor: pointer;
  padding-right: 1.2rem;
}
.cux-textbox__icon--primary {
  color: #1da3d4;
}
.cux-textbox__fileName {
  display: flex;
  justify-content: flex-start;
}
.cux-textbox--error {
  border: 2px solid #d23927 !important;
}
.cux-textbox--success {
  border: 2px solid #1da3d4 !important;
}

.breadcrumbs {
  margin-top: 3rem;
  display: flex;
  gap: 1rem;
}
.breadcrumbs--active {
  color: #606569;
}
.breadcrumbs--in-active {
  cursor: pointer;
  color: #1da3d4;
}

.stepper-error-disclaimer {
  font-size: 1.4rem;
  height: 6.5rem;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  padding: 2.7rem;
  border: 0.05rem solid;
  border-color: #d23927;
  background: #ffe8e8;
  min-width: 76rem;
  max-width: 82rem;
}
.stepper-error-disclaimer a {
  color: #1da3d4;
  text-decoration: none;
  cursor: pointer;
}

.toggle-group-design .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.toggle-group-design .mat-button-toggle-group-appearance-standard {
  border: none;
}
.toggle-group-design .mat-button-toggle button {
  border-radius: 0.4rem;
  border: none;
  font-weight: 500;
  cursor: pointer;
  background-color: #f7f7f7;
}
.toggle-group-design .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 3rem;
  color: #606569;
}
.toggle-group-design .mat-button-toggle.active .mat-button-toggle-label-content {
  color: #fff;
  background-color: #1da3d4;
}
.toggle-group-design .mat-button-toggle-appearance-standard {
  background-color: #f7f7f7;
}
.toggle-group-design-border .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 2.5rem;
}
.toggle-group-design-border .mat-button-toggle button {
  margin: 0.35rem;
  width: auto;
}
.toggle-group-design-border .mat-button-toggle-checked button {
  margin: 0.25rem;
}

.mat-button-toggle .mat-pseudo-checkbox {
  display: none;
}

.toggle-group-design .mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard .mat-button-toggle-appearance-standard + .mat-button-toggle-appearance-standard {
  border-left: 0.1rem solid #D9D9D9 !important;
  border: 0.1rem solid #D9D9D9 !important;
  border-radius: 0.5rem;
}

.mat-mdc-menu-panel {
  max-width: 22rem !important;
  max-height: 27rem !important;
  min-width: 15.2rem !important;
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-size: 1.4rem !important;
  line-height: 1.5rem !important;
}

.mat-mdc-menu-item {
  min-height: 3.5rem !important;
  color: #606569 !important;
  --mat-menu-item-hover-state-layer-color: #1da3d4;
  --mat-menu-item-focus-state-layer-color: transparent;
}
.mat-mdc-menu-item:hover {
  color: #fff !important;
  --mat-menu-item-focus-state-layer-color: #1da3d4;
}
.mat-mdc-menu-item:hover .mat-mdc-menu-submenu-icon {
  color: #fff !important;
}
.mat-mdc-menu-item .mat-mdc-menu-submenu-icon {
  color: #606569 !important;
}