@use '@angular/material' as mat;
@import "./variables";
@include mat.core();

$light-primary-text: $color-white;
$dark-primary-text: rgba($color-white, 0.87);

$base-palette: (
  50: #e8eaf6,
  100: #c8bcd8,
  // Lighter
  200: #9fa8da,
  300: #8ccfe8,
  400: #5c6bc0,
  500: $color-primary,
  // Primary
  600: #24a3d1,
  700: #1DA3D4,
  // Darker
  800: #2f1260,
  900: #049dd5,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$my-primary: mat.define-palette($base-palette, 500);
$my-accent: mat.define-palette($base-palette, A200, A100, A400);
$my-typography: mat.define-typography-config();

$my-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
      ),
      typography: $my-typography,
    ));

@include mat.all-component-themes($my-theme);

// Material Custom themes specially for Electica

.mdc-checkbox__background {
  width: 1.5rem !important;
  height: 1.5rem !important;
  color: $color-border;
  border: 1px solid $color-border !important;
}

.mdc-form-field>label {
  padding-left: 0;
  margin-top: -0.7rem;
  margin-left: -1rem !important;
  color: $color-font;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: $color-white;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #{$color-white};
  --mdc-snackbar-supporting-text-color: #{$color-text};
  --mat-snack-bar-button-color: #{$color-primary};
}

.mdc-checkbox__ripple {
  display: none !important;
}

.mat-expansion-panel {
  box-shadow: none !important;
}

.mdc-checkbox__background {
  border: .2rem solid $color-primary !important;
  height: 2rem !important;
  width: 2rem !important;
  color: $color-white !important;
}

.mdc-checkbox {
  margin-right: 1rem !important;
}

.mat-mdc-checkbox label {
  margin-top: .2rem;
  color: $color-text;
}

.mat-mdc-paginator {
  margin-bottom: 2rem;
  border-radius: 1rem;
}