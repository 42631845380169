*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; // 1rem = 10px/16px = 62.5%

  @include respond(tab-land) {
    font-size: 56.25%;
  }

  @include respond(tab-port) {
    font-size: 50%; // 1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; // 1rem = 12. 12/16 = 75%
  }

}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter-VariableFont_opsz,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter-Italic-VariableFont_opsz,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: italic;
  font-display: swap;
}

* {
  font-family: 'Inter', sans-serif;
}

body {
  box-sizing: border-box;
  font-size: 1.4rem;
  color: $color-font;
  background-color: $color-grey-light-1;
  font-weight: 400;
  line-height: 1.5;
}

p {
  font-weight: 400;
}

em,
i {
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-size: 1.2rem;
  color: $color-font;
}

h2 {
  color: $color-primary;
  font-size: 1.8rem;
  font-weight: 500;
}

a {
  color: $color-primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/* For Chrome and Edge */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3rem $color-background inset !important;
  box-shadow: 0 0 0 3rem $color-background inset !important;
}

/* For Firefox */
input:-moz-autofill {
  background-color: $color-background !important;
}

/* For Safari */
input:-webkit-autofill {
  background-color: $color-background !important;
}

button,
table {
  box-shadow: none !important;
}