// Colors
$color-primary: #1da3d4;
$color-hover: #3dbdec;
$color-font: #606569;
$color-background: #f5f7f8;
$color-background-1: #f2f2f2;
$color-font-placeholder: #bbc3c9;
$color-border: #bbc3c9;
$color-muted: #bbc3c9;
$color-border: #d9d9d9;
$color-text: #404041;

$color-red: #d23927;
$color-red-error: #ffe8e8;
$color-red-1: #cf5f5f;

$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #e5c326;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;
$color-grey-light-3: #717680;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;
$color-gray: #fafafa;

$color-hover-light: #3dbdec0f;