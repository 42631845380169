.stepper-error-disclaimer {
    font-size: 1.4rem;
    height: 6.5rem;
    display: flex;
    align-items: center;
    border-radius: 1rem;
    padding: 2.7rem;
    border: 0.05rem solid;
    border-color: $color-red;
    background: $color-red-error;
    min-width: 76rem;
    max-width: 82rem;

    a {
        color: $color-primary;
        text-decoration: none;
        cursor: pointer;
    }
}